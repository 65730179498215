import "styles/pages/page-information.scss";
import React from "react";
import { Link } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";
import ProjectBanner from "../components/ProjectBanner";

const InformationPage = ({ pageContext }) => {
   const seo = pageContext.seo;
   const parent = pageContext.parent;
   return (
      <Layout>
         <Seo
            title={seo.title ? seo.title : pageContext.title}
            description={seo.metaDesc}
         />
         <Breadcrumbs
            currentLocationLabel={pageContext.title}
            prevLocationLabel={parent.title}
            prevLocationLink={parent.uri}
         />
         <div className="container">
            <section className="section-info">
               <img
                  src={pageContext.data.image?.sourceUrl}
                  alt={pageContext.data.image?.altText}
                  className="section-info__hero-img"
               />
               <SectionTitle title={pageContext.title} />
               <div
                  className="section-info__paragraph"
                  dangerouslySetInnerHTML={{ __html: pageContext.content }}
               />
               {pageContext.data.footerNote === "true" && (
                  <div className="section-info__note">
                     Chcesz wiedzieć więcej - przeczytaj{" "}
                     <Link to="/regulamin-glosowania">Regulamin</Link>, a potem{" "}
                     <Link to="/kontakt-do-doradcow">
                        skontaktuj się z nami
                     </Link>
                     .
                  </div>
               )}
               <ProjectBanner
                  title="Zgłoś swój projekt"
                  link="https://www.witkac.pl/Account/Login"
                  btnText="Przejdź do generatora"
               />
            </section>
         </div>
      </Layout>
   );
};

export default InformationPage;
