import "./project-banner.scss";
import React from "react";

const ProjectBanner = ({ link, title, btnText, onClick }) => {
   return (
      <div className="project-banner">
         <h2 className="project-banner__title">{title}</h2>
         {link && (
            <a
               type="button"
               href={link}
               className="project-banner__button button"
               target="_blank"
               rel="noopener noreferrer nofollow"
            >
               {btnText}
            </a>
         )}
         {onClick && (
            <button
               type="button"
               className="project-banner__button button"
               target="_blank"
               rel="noopener noreferrer nofollow"
               onClick={onClick}
            >
               {btnText}
            </button>
         )}
      </div>
   );
};

export default ProjectBanner;
